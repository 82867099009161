export const statuses = [
  {
    value: null,
    label: '-- Chọn --'
  },
  {
    value: true,
    label: 'Hoạt động'
  },
  {
    value: false,
    label: 'Không hoạt động'
  }
]

export const loaiDonVis = [
  {
    value: null,
    label: '-- Chọn --'
  },
  {
    value: 1,
    label: 'Đơn vị đầu mối'
  },
  {
    value: 2,
    label: 'Bộ/ Cơ quan ngang Bộ/ UBND cấp Tỉnh'
  },
  {
    value: 3,
    label: 'Đơn vị thuộc Bộ KHĐT'
  },
  {
    value: 4,
    label: 'Đơn vị thuộc Bộ/Tỉnh'
  },
  {
    value: 5,
    label: 'Đơn vị khác'
  }
]

export function displayLabel (arr, value) {
  return arr.find(x => x.value === value)?.label
}

export function displayLabel2 (arr, value) {
  const a = arr.find(x => x.value === String(value))
  if (a) return a.label
  else return value
}
